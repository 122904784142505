import type { FetchOptions } from '@canalplus/mycanal-fetch';
import type { LogApiMetadata, Logger } from '@canalplus/mycanal-logger';
import type { HodorPlatform } from '@canalplus/sdk-core';
import { OfferLocation, OfferZone } from '@canalplus/sdk-core';
import type { ApiV2InitResponse } from '@dce-front/hodor-types';

export type HodorSdkConfigBase = {
  /** AppKey application, for log purpose */
  appKey: string;

  /** Hodor arbo version to be used */
  arboVersion: string;

  /**
   * Hodor base url to be used\
   * To be concatenated with `paths` values
   */
  baseUrl: `http${'s' | ''}://${string}/{appKey}`;

  /** A valid `HodorPlatform` */
  device: HodorPlatform;

  /** A valid {@link @canalplus/mycanal-logger!Logger} instance */
  logger: Logger;

  /** A valid {@link @canalplus/sdk-core!OfferLocation} */
  offerLocation: OfferLocation;

  /** A valid {@link @canalplus/sdk-core!OfferZone} */
  offerZone: OfferZone;

  /** User's passToken */
  passToken: string;

  /** Paths to different Hodor API */
  paths: {
    init: `/${string}/{offerZone}/${string}/{device}/{arboVersion}`;
    wsFromPath: `/${string}/{cmsToken}`;
    configuration: `/${string}/{device}/{version}`;
  };

  /** `accept-language` headers request */
  acceptLanguage?: string;

  /** Id to use when `isAnalyticsEnabled` is true */
  analyticsId?: string;

  /** Id to use when `isAnonymousAnalyticsEnabled` is true */
  anonymousId?: string;

  cmsToken?: string;

  /** Current user deviceId */
  deviceId?: string;

  /** A {@link @canalplus/mycanal-fetch!FetchOptions} object to be given on each Hodor fetch */
  fetchOptions?: FetchOptions;

  /** Whether analytics tracking is enabled or not */
  isAnalyticsEnabled?: boolean;

  /** Whether anonymous analytics tracking is enabled or not */
  isAnonymousAnalyticsEnabled?: boolean;

  /** User macros values */
  macros?: string;

  /** User micros values */
  micros?: string;

  /** Base logging attributes that will be merged with sdk logging */
  logMetadata?: Partial<LogApiMetadata>;

  /**
   * Optional callback that handle passToken renewal
   * @returns a new passToken
   */
  passTokenRenewalCallback?: () => Promise<string>;

  /** Current user profileId */
  profileId?: string;

  /** Request id, for log purpose */
  requestId?: string;

  /**
   * Rewrite every url before fetching it\
   * Useful for managed network (eg: Orange)
   */
  rewriteUrlCallback?: (url: string) => string;

  /** Urls to different Hodor API */
  urls?: {
    authenticate?: string;
    avatars?: string;
  };
};

/** Drogon parameters returned by init */
export type HodorSdkConfigDrogonInitParameters = {
  crmSegments?: ApiV2InitResponse['userDetails']['crmSegments'];
  experiments?: ApiV2InitResponse['userDetails']['experiments'];
  featureToggles?: ApiV2InitResponse['userDetails']['featureToggles'];
  like?: ApiV2InitResponse['userDetails']['like'];
  segments?: ApiV2InitResponse['userDetails']['segments'];
};

export type HodorSdkConfig = HodorSdkConfigBase &
  HodorSdkConfigDrogonInitParameters;

/** `HodorSdkConfig` without user personal data */
export type HodorSdkConfigAnonymized = Omit<
  HodorSdkConfig,
  | 'analyticsId'
  | 'anonymousId'
  | 'crmSegments'
  | 'deviceId'
  | 'experiments'
  | 'featureToggles'
  | 'isAnalyticsEnabled'
  | 'isAnonymousAnalyticsEnabled'
  | 'like'
  | 'macros'
  | 'micros'
  | 'passToken'
  | 'profileId'
  | 'tokenCMS'
>;

/** A transformed `HodorSdkConfig` to match with Hodor Api */
export type HodorApiConfig = Omit<
  HodorSdkConfig,
  | 'acceptLanguage'
  | 'analyticsId'
  | 'anonymousId'
  | 'baseUrl'
  | 'fetchOptions'
  | 'isAnalyticsEnabled'
  | 'isAnonymousAnalyticsEnabled'
  | 'logger'
  | 'logMetadata'
  | 'passToken'
  | 'passTokenRenewalCallback'
  | 'paths'
  | 'profileId'
  | 'rewriteUrlCallback'
  | 'urls'
> & {
  'accept-language': HodorSdkConfig['acceptLanguage'];
  collectUserData?: '0' | '1';
  language: HodorSdkConfig['acceptLanguage'];
  tokenPass: HodorSdkConfig['passToken'];
  userId: HodorSdkConfig['analyticsId'] | HodorSdkConfig['anonymousId'];
  'xx-profile-id': HodorSdkConfig['profileId'];
};

export type HodorApiParameterId = keyof HodorApiConfig | 'tokenCMS';

/** Used by `isHodorApiParameterId` to not have to repeat all the allowed keys manually */
const hodorApiParameterIdPossibilities: Required<HodorApiConfig> = {
  'accept-language': '',
  'xx-profile-id': '',
  appKey: '',
  arboVersion: '',
  collectUserData: '0',
  crmSegments: [],
  device: 'g9',
  deviceId: '',
  experiments: [],
  featureToggles: [],
  language: '',
  like: [],
  macros: '',
  micros: '',
  offerLocation: OfferLocation.fr,
  offerZone: OfferZone.cpfra,
  requestId: '',
  segments: [],
  cmsToken: '',
  tokenPass: '',
  userId: '',
};

/** `HodorApiParameterId` type guard */
const isHodorApiParameterId = (value: string): value is HodorApiParameterId =>
  Object.keys(hodorApiParameterIdPossibilities).includes(value);

export type HodorApiParameterIn = 'header' | 'path' | 'parameters';

/** `HodorApiParameterIn` type guard */
const isHodorApiParameterIn = (value: string): value is HodorApiParameterIn =>
  ['header', 'path', 'parameters'].includes(value);

export type HodorApiParameter = {
  id: HodorApiParameterId;
  in: HodorApiParameterIn;
  enum?: (string | number)[];
};

type IsHodorApiParameterParameters =
  | { id?: string; in?: string; enum?: any[] }
  | undefined;

/** `HodorApiParameter` type guard */
export const isHodorApiParameter = (
  value: IsHodorApiParameterParameters,
): value is HodorApiParameter => {
  if (value === undefined) {
    return false;
  }

  const { id, in: inValue, enum: enumValues } = value;

  // value has a valid id property
  if (typeof id !== 'string' || !isHodorApiParameterId(id)) {
    return false;
  }

  // value has a valid in property
  if (typeof inValue !== 'string' || !isHodorApiParameterIn(inValue)) {
    return false;
  }

  // value has an undefined enum
  if (enumValues === undefined) {
    return true;
  }

  // value has a valid enum property
  if (
    enumValues.every(
      (enumValue) =>
        typeof enumValue === 'string' || typeof enumValue === 'number',
    )
  ) {
    return true;
  }

  return false;
};
