import {
  type DiveViewportsWithDevice,
  type ViewportToImageDimensions,
} from '@canalplus/dive';
import { Ratio } from '@canalplus/mycanal-commons';

export type ImageSize = 'small' | 'normal' | 'large' | 'extraLarge';

const RATIO_166 = {
  normal: {
    tv1080p: { width: 545, height: 204, ratio: Ratio.Ratio166 },
    tv720p: { width: 363, height: 136, ratio: Ratio.Ratio166 },
    XLarge: { width: 573, height: 215, ratio: Ratio.Ratio166 },
    large: { width: 424, height: 159, ratio: Ratio.Ratio166 },
    medium: { width: 424, height: 159, ratio: Ratio.Ratio166 },
    small: { width: 573, height: 215, ratio: Ratio.Ratio166 },
    XSmall: { width: 352, height: 132, ratio: Ratio.Ratio166 },
  },
} as const satisfies Record<
  Exclude<ImageSize, 'small' | 'large' | 'extraLarge'>,
  ViewportToImageDimensions
>;

// example of classic strates on landing
const RATIO_169 = {
  normal: {
    tv1080p: { width: 396, height: 222, ratio: Ratio.Ratio169 },
    tv720p: { width: 264, height: 148, ratio: Ratio.Ratio169 },
    XLarge: { width: 322, height: 181, ratio: Ratio.Ratio169 },
    large: { width: 254, height: 143, ratio: Ratio.Ratio169 },
    medium: { width: 226, height: 127, ratio: Ratio.Ratio169 },
    small: { width: 322, height: 181, ratio: Ratio.Ratio169 },
    XSmall: { width: 169, height: 95, ratio: Ratio.Ratio169 },
  },
  large: {
    tv1080p: { width: 545, height: 306, ratio: Ratio.Ratio169 },
    tv720p: { width: 363, height: 204, ratio: Ratio.Ratio169 },
    XLarge: { width: 462, height: 260, ratio: Ratio.Ratio169 },
    large: { width: 460, height: 259, ratio: Ratio.Ratio169 },
    medium: { width: 354, height: 199, ratio: Ratio.Ratio169 },
    small: { width: 659, height: 371, ratio: Ratio.Ratio169 },
    XSmall: { width: 334, height: 188, ratio: Ratio.Ratio169 },
  },
} as const satisfies Record<
  Exclude<ImageSize, 'small' | 'extraLarge'>,
  ViewportToImageDimensions
>;

const LIVE_GRID_CARD = {
  large: {
    tv1080p: { width: 396, height: 222, ratio: Ratio.Ratio169 },
    tv720p: { width: 292, height: 164, ratio: Ratio.Ratio169 },
  },
} as const satisfies Record<
  Exclude<ImageSize, 'small' | 'normal' | 'extraLarge'>,
  ViewportToImageDimensions
>;

const RATIO_23 = {
  normal: {
    XLarge: { width: 172, height: 258, ratio: Ratio.Ratio23 },
    large: { width: 177, height: 266, ratio: Ratio.Ratio23 },
    medium: { width: 181, height: 271, ratio: Ratio.Ratio23 },
    small: { width: 153, height: 230, ratio: Ratio.Ratio23 },
    XSmall: { width: 141, height: 211, ratio: Ratio.Ratio23 },
  },
  large: {
    XLarge: { width: 249, height: 374, ratio: Ratio.Ratio23 },
    large: { width: 239, height: 358, ratio: Ratio.Ratio23 },
    medium: { width: 256, height: 384, ratio: Ratio.Ratio23 },
    small: { width: 234, height: 351, ratio: Ratio.Ratio23 },
    XSmall: { width: 240, height: 360, ratio: Ratio.Ratio23 },
  },
} as const satisfies Record<
  Exclude<ImageSize, 'small' | 'extraLarge'>,
  ViewportToImageDimensions
>;

// example of strate on landing, small and large
const RATIO_34 = {
  normal: {
    tv1080p: { width: 246, height: 328, ratio: Ratio.Ratio34 },
    tv720p: { width: 164, height: 219, ratio: Ratio.Ratio34 },
    XLarge: { width: 253, height: 337, ratio: Ratio.Ratio34 },
    large: { width: 180, height: 239, ratio: Ratio.Ratio34 },
    medium: { width: 133, height: 177, ratio: Ratio.Ratio34 },
    small: { width: 220, height: 292, ratio: Ratio.Ratio34 },
    XSmall: { width: 111, height: 148, ratio: Ratio.Ratio34 },
  },
  large: {
    tv1080p: { width: 396, height: 527, ratio: Ratio.Ratio34 },
    tv720p: { width: 264, height: 352, ratio: Ratio.Ratio34 },
    XLarge: { width: 331, height: 441, ratio: Ratio.Ratio34 },
    large: { width: 256, height: 340, ratio: Ratio.Ratio34 },
    medium: { width: 230, height: 305, ratio: Ratio.Ratio34 },
    small: { width: 322, height: 432, ratio: Ratio.Ratio34 },
    XSmall: { width: 170, height: 226, ratio: Ratio.Ratio34 },
  },
  // example of ContentTabs in showcase
  small: {
    XLarge: { width: 150, height: 199, ratio: Ratio.Ratio34 },
    large: { width: 150, height: 199, ratio: Ratio.Ratio34 },
    medium: { width: 138, height: 184, ratio: Ratio.Ratio34 },
    small: { width: 221, height: 294, ratio: Ratio.Ratio34 },
    XSmall: { width: 113, height: 150, ratio: Ratio.Ratio34 },
  },
} as const satisfies Record<
  Exclude<ImageSize, 'extraLarge'>,
  ViewportToImageDimensions
>;

const RATIO_43 = {
  normal: {
    tv1080p: { width: 246, height: 185, ratio: Ratio.Ratio43 },
    tv720p: { width: 164, height: 123, ratio: Ratio.Ratio43 },
    XLarge: { width: 200, height: 150, ratio: Ratio.Ratio43 },
    large: { width: 176, height: 132, ratio: Ratio.Ratio43 },
    medium: { width: 129, height: 97, ratio: Ratio.Ratio43 },
    small: { width: 216, height: 162, ratio: Ratio.Ratio43 },
    XSmall: { width: 108, height: 81, ratio: Ratio.Ratio43 },
  },
  large: {
    tv1080p: { width: 246, height: 185, ratio: Ratio.Ratio43 },
    tv720p: { width: 116, height: 87, ratio: Ratio.Ratio43 },
    XLarge: { width: 331, height: 248, ratio: Ratio.Ratio43 },
    large: { width: 254, height: 191, ratio: Ratio.Ratio43 },
    medium: { width: 227, height: 171, ratio: Ratio.Ratio43 },
    small: { width: 193, height: 145, ratio: Ratio.Ratio43 },
    XSmall: { width: 141, height: 106, ratio: Ratio.Ratio43 },
  },
} as const satisfies Record<
  Exclude<ImageSize, 'small' | 'extraLarge'>,
  ViewportToImageDimensions
>;

const RATIO_47 = {
  normal: {
    XLarge: { width: 172, height: 301, ratio: Ratio.Ratio47 },
    large: { width: 177, height: 309, ratio: Ratio.Ratio47 },
    medium: { width: 181, height: 316, ratio: Ratio.Ratio47 },
    small: { width: 153, height: 268, ratio: Ratio.Ratio47 },
    XSmall: { width: 141, height: 246, ratio: Ratio.Ratio47 },
  },
  large: {
    XLarge: { width: 249, height: 436, ratio: Ratio.Ratio47 },
    large: { width: 239, height: 418, ratio: Ratio.Ratio47 },
    medium: { width: 256, height: 448, ratio: Ratio.Ratio47 },
    small: { width: 234, height: 409, ratio: Ratio.Ratio47 },
    XSmall: { width: 240, height: 420, ratio: Ratio.Ratio47 },
  },
} as const satisfies Record<
  Exclude<ImageSize, 'small' | 'extraLarge'>,
  ViewportToImageDimensions
>;

const RATIO_SQUARE = {
  normal: {
    XLarge: { width: 172, height: 172, ratio: Ratio.Square },
    large: { width: 177, height: 177, ratio: Ratio.Square },
    medium: { width: 181, height: 181, ratio: Ratio.Square },
    small: { width: 153, height: 153, ratio: Ratio.Square },
    XSmall: { width: 141, height: 141, ratio: Ratio.Square },
  },
  large: {
    XLarge: { width: 249, height: 249, ratio: Ratio.Square },
    large: { width: 239, height: 239, ratio: Ratio.Square },
    medium: { width: 256, height: 256, ratio: Ratio.Square },
    small: { width: 234, height: 234, ratio: Ratio.Square },
    XSmall: { width: 240, height: 240, ratio: Ratio.Square },
  },
} as const satisfies Record<
  Exclude<ImageSize, 'small' | 'extraLarge'>,
  ViewportToImageDimensions
>;

// used in the landing carrousel
const CARROUSEL = {
  [Ratio.Ratio166]: {
    tv1080p: { width: 843, height: 473, ratio: Ratio.Ratio169 },
    tv720p: { width: 562, height: 315, ratio: Ratio.Ratio169 },
    XLarge: { width: 810, height: 303, ratio: Ratio.Ratio166 },
    large: { width: 655, height: 245, ratio: Ratio.Ratio166 },
    medium: { width: 347, height: 130, ratio: Ratio.Ratio166 },
    small: { width: 669, height: 251, ratio: Ratio.Ratio166 },
    XSmall: { width: 344, height: 129, ratio: Ratio.Ratio166 },
  },
  [Ratio.Ratio169]: {
    tv1080p: { width: 843, height: 473, ratio: Ratio.Ratio169 },
    tv720p: { width: 562, height: 315, ratio: Ratio.Ratio169 },
    XLarge: { width: 808, height: 455, ratio: Ratio.Ratio169 },
    large: { width: 650, height: 366, ratio: Ratio.Ratio169 },
    medium: { width: 329, height: 185, ratio: Ratio.Ratio169 },
    small: { width: 669, height: 375, ratio: Ratio.Ratio169 },
    XSmall: { width: 345, height: 194, ratio: Ratio.Ratio169 },
  },
  [Ratio.Ratio43]: {
    tv1080p: { width: 843, height: 473, ratio: Ratio.Ratio169 },
    tv720p: { width: 562, height: 315, ratio: Ratio.Ratio169 },
    XLarge: { width: 403, height: 304, ratio: Ratio.Ratio43 },
    large: { width: 324, height: 243, ratio: Ratio.Ratio43 },
    medium: { width: 324, height: 243, ratio: Ratio.Ratio43 },
    small: { width: 669, height: 502, ratio: Ratio.Ratio43 },
    XSmall: { width: 344, height: 258, ratio: Ratio.Ratio43 },
  },
} as const satisfies Record<
  Ratio.Ratio166 | Ratio.Ratio169 | Ratio.Ratio43,
  ViewportToImageDimensions
>;

const PROFILE_AVATAR = {
  small: {
    XLarge: { width: 22, height: 22 },
    large: { width: 22, height: 22 },
    medium: { width: 22, height: 22 },
    small: { width: 22, height: 22 },
    XSmall: { width: 22, height: 22 },
  },
  normal: {
    tv1080p: { width: 208, height: 208 },
    tv720p: { width: 139, height: 139 },
    XLarge: { width: 60, height: 60 },
    large: { width: 60, height: 60 },
    medium: { width: 60, height: 60 },
    small: { width: 60, height: 60 },
    XSmall: { width: 60, height: 60 },
  },
  large: {
    tv1080p: { width: 208, height: 208 },
    tv720p: { width: 139, height: 139 },
    XLarge: { width: 88, height: 88 },
    large: { width: 88, height: 88 },
    medium: { width: 88, height: 88 },
    small: { width: 88, height: 88 },
    XSmall: { width: 88, height: 88 },
  },
  extraLarge: {
    tv1080p: { width: 208, height: 208 },
    tv720p: { width: 139, height: 139 },
    XLarge: { width: 221, height: 221 },
    large: { width: 221, height: 221 },
    medium: { width: 221, height: 221 },
    small: { width: 221, height: 221 },
    XSmall: { width: 221, height: 221 },
  },
} as const satisfies Record<ImageSize, ViewportToImageDimensions>;

// example of logo for liveRow and classic strates
const LOGO_CHANNEL = {
  normal: {
    tv1080p: { width: 90, height: 68, ratio: Ratio.Ratio43 },
    tv720p: { width: 60, height: 45, ratio: Ratio.Ratio43 },
    XLarge: { width: 48, height: 36, ratio: Ratio.Ratio43 },
    large: { width: 48, height: 36, ratio: Ratio.Ratio43 },
    medium: { width: 48, height: 36, ratio: Ratio.Ratio43 },
    small: { width: 48, height: 36, ratio: Ratio.Ratio43 },
    XSmall: { width: 48, height: 36, ratio: Ratio.Ratio43 },
  },
  large: {
    tv1080p: { width: 88, height: 66, ratio: Ratio.Ratio43 },
    tv720p: { width: 88, height: 66, ratio: Ratio.Ratio43 },
    XLarge: { width: 132, height: 99, ratio: Ratio.Ratio43 },
    large: { width: 101, height: 76, ratio: Ratio.Ratio43 },
    medium: { width: 92, height: 69, ratio: Ratio.Ratio43 },
    small: { width: 96, height: 72, ratio: Ratio.Ratio43 },
    XSmall: { width: 96, height: 72, ratio: Ratio.Ratio43 },
  },
  extraLarge: {
    tv1080p: { width: 175, height: 131, ratio: Ratio.Ratio43 },
    tv720p: { width: 116, height: 87, ratio: Ratio.Ratio43 },
    XLarge: { width: 160, height: 120, ratio: Ratio.Ratio43 },
    large: { width: 160, height: 120, ratio: Ratio.Ratio43 },
    medium: { width: 160, height: 120, ratio: Ratio.Ratio43 },
    small: { width: 76, height: 57, ratio: Ratio.Ratio43 },
    XSmall: { width: 76, height: 57, ratio: Ratio.Ratio43 },
  },
} as const satisfies Record<
  Exclude<ImageSize, 'small'>,
  ViewportToImageDimensions
>;

// example of large image present in landing or IdentityCover
const COVER_IMAGE = {
  tv1080p: { width: 1920, height: 500, ratio: Ratio.Ratio236 },
  tv720p: { width: 1280, height: 333, ratio: Ratio.Ratio236 },
  XLarge: { width: 2560, height: 669, ratio: Ratio.Ratio236 },
  large: { width: 1440, height: 376, ratio: Ratio.Ratio236 },
  medium: { width: 769, height: 201, ratio: Ratio.Ratio236 },
  small: { width: 501, height: 282, ratio: Ratio.Ratio169 },
  XSmall: { width: 501, height: 282, ratio: Ratio.Ratio169 },
} as const satisfies ViewportToImageDimensions;

const LIST_EPISODES = {
  tv1080p: { width: 503, height: 283, ratio: Ratio.Ratio169 },
  tv720p: { width: 349, height: 197, ratio: Ratio.Ratio169 },
  XLarge: { width: 462, height: 260, ratio: Ratio.Ratio169 },
  large: { width: 305, height: 171, ratio: Ratio.Ratio169 },
  medium: { width: 240, height: 135, ratio: Ratio.Ratio169 },
  small: { width: 240, height: 135, ratio: Ratio.Ratio169 },
  XSmall: { width: 240, height: 135, ratio: Ratio.Ratio169 },
} as const satisfies ViewportToImageDimensions;

// example of banner present in landing
const BANNER = {
  tv1080p: { width: 1764, height: 454, ratio: Ratio.Ratio236 },
  tv720p: { width: 1176, height: 302, ratio: Ratio.Ratio236 },
  XLarge: { width: 2408, height: 628, ratio: Ratio.Ratio236 },
  large: { width: 1300, height: 339, ratio: Ratio.Ratio236 },
  medium: { width: 697, height: 182, ratio: Ratio.Ratio236 },
  small: { width: 669, height: 376, ratio: Ratio.Ratio169 },
  XSmall: { width: 344, height: 193, ratio: Ratio.Ratio169 },
} as const satisfies ViewportToImageDimensions;

const LOGO_CHANNELS_PROGRAM_GUIDE = {
  XLarge: { width: 121, height: 91, ratio: Ratio.Ratio43 },
  large: { width: 101, height: 76, ratio: Ratio.Ratio43 },
  medium: { width: 89, height: 67, ratio: Ratio.Ratio43 },
  small: { width: 178, height: 100, ratio: Ratio.Ratio169 },
  XSmall: { width: 100, height: 75, ratio: Ratio.Ratio43 },
} as const satisfies ViewportToImageDimensions;

const SHOWCASE = {
  banner: {
    XLarge: { width: 960, height: 139 },
    large: { width: 960, height: 139 },
    medium: { width: 708, height: 102 },
    small: { width: 559, height: 313 },
    XSmall: { width: 395, height: 221 },
  },
  tiles: {
    XLarge: { width: 120, height: 92 },
    large: { width: 120, height: 92 },
    medium: { width: 120, height: 92 },
    small: { width: 120, height: 92 },
    XSmall: { width: 120, height: 92 },
  },
  image: {
    XLarge: { width: 960, height: 382 },
    large: { width: 960, height: 382 },
    medium: { width: 708, height: 282 },
    small: { width: 644, height: 257 },
    XSmall: { width: 392, height: 156 },
  },
  mosaic: {
    XLarge: { width: 119, height: 89 },
    large: { width: 86, height: 62 },
    medium: { width: 64, height: 48 },
    small: { width: 64, height: 48 },
    XSmall: { width: 63, height: 48 },
  },
} as const satisfies Record<
  'banner' | 'tiles' | 'image' | 'mosaic',
  ViewportToImageDimensions
>;

const OFFER_HIGHLIGHT = {
  image: {
    tv1080p: { width: 1920, height: 840 },
    tv720p: { width: 1280, height: 612 },
    XLarge: { width: 1920, height: 1080, ratio: Ratio.Ratio169 },
    large: { width: 1920, height: 1080, ratio: Ratio.Ratio169 },
    medium: { width: 1280, height: 720, ratio: Ratio.Ratio169 },
    small: { width: 600, height: 800, ratio: Ratio.Ratio34 },
    XSmall: { width: 300, height: 400, ratio: Ratio.Ratio34 },
  },
  logo: {
    XLarge: { width: 184, height: 48, ratio: Ratio.Ratio236 },
    large: { width: 184, height: 48, ratio: Ratio.Ratio236 },
    medium: { width: 184, height: 48, ratio: Ratio.Ratio236 },
    small: { width: 184, height: 48, ratio: Ratio.Ratio236 },
    XSmall: { width: 184, height: 48, ratio: Ratio.Ratio236 },
  },
} as const satisfies Record<'image' | 'logo', ViewportToImageDimensions>;

const MULTI_LIVE_SETUP = {
  XLarge: { width: 190, height: 107, ratio: Ratio.Ratio169 },
  large: { width: 296, height: 166, ratio: Ratio.Ratio169 },
  medium: { width: 174, height: 98, ratio: Ratio.Ratio169 },
  small: { width: 148, height: 83, ratio: Ratio.Ratio169 },
  XSmall: { width: 148, height: 83, ratio: Ratio.Ratio169 },
} as const satisfies ViewportToImageDimensions;

const COVER_DETAIL_V5 = {
  [Ratio.Ratio34]: {
    XLarge: { width: 1099, height: 1456, ratio: Ratio.Ratio34 },
    large: { width: 888, height: 1177, ratio: Ratio.Ratio34 },
    medium: { width: 699, height: 926, ratio: Ratio.Ratio34 },
    small: { width: 509, height: 675, ratio: Ratio.Ratio34 },
    XSmall: { width: 373, height: 495, ratio: Ratio.Ratio34 },
  },
  [Ratio.Ratio169]: {
    tv1080p: { width: 1920, height: 974, ratio: Ratio.Ratio169 },
    tv720p: { width: 1280, height: 649, ratio: Ratio.Ratio169 },
    XLarge: { width: 1824, height: 1026, ratio: Ratio.Ratio169 },
    large: { width: 1440, height: 810, ratio: Ratio.Ratio169 },
    medium: { width: 768, height: 432, ratio: Ratio.Ratio169 },
    small: { width: 700, height: 394, ratio: Ratio.Ratio169 },
    XSmall: { width: 495, height: 278, ratio: Ratio.Ratio169 },
  },
} as const satisfies Record<
  Ratio.Ratio34 | Ratio.Ratio169,
  ViewportToImageDimensions
>;

const DETAIL_LIGHT = {
  tv1080p: { width: 1520, height: 855, ratio: Ratio.Ratio169 },
  tv720p: { width: 1013, height: 570, ratio: Ratio.Ratio169 },
  XLarge: { width: 1280, height: 720, ratio: Ratio.Ratio169 },
  large: { width: 1280, height: 720, ratio: Ratio.Ratio169 },
  medium: { width: 768, height: 432, ratio: Ratio.Ratio169 },
  small: { width: 700, height: 394, ratio: Ratio.Ratio169 },
  XSmall: { width: 374, height: 210, ratio: Ratio.Ratio169 },
} as const satisfies ViewportToImageDimensions;

const HEADER_LOGO = {
  XLarge: { width: 128, height: 32 },
  large: { width: 128, height: 32 },
  medium: { width: 128, height: 32 },
  small: { width: 128, height: 32 },
  XSmall: { width: 128, height: 32 },
} as const satisfies ViewportToImageDimensions;

const LOGO_TITLE_V5 = {
  tv1080p: { width: 640, height: 360, ratio: Ratio.Ratio169 },
  tv720p: { width: 427, height: 240, ratio: Ratio.Ratio169 },
  XLarge: { width: 380, height: 214, ratio: Ratio.Ratio169 },
  large: { width: 380, height: 214, ratio: Ratio.Ratio169 },
  medium: { width: 280, height: 157, ratio: Ratio.Ratio169 },
  small: { width: 280, height: 157, ratio: Ratio.Ratio169 },
  XSmall: { width: 280, height: 157, ratio: Ratio.Ratio169 },
} as const satisfies ViewportToImageDimensions;

const LOGOTYPE_IDENTITY_V5 = {
  XLarge: { width: 480, height: 270, ratio: Ratio.Ratio169 },
  large: { width: 280, height: 157, ratio: Ratio.Ratio169 },
  medium: { width: 280, height: 157, ratio: Ratio.Ratio169 },
  small: { width: 192, height: 108, ratio: Ratio.Ratio169 },
  XSmall: { width: 192, height: 108, ratio: Ratio.Ratio169 },
} as const satisfies ViewportToImageDimensions;

const LOGO_CHANNEL_IDENTITY_V5 = {
  XLarge: { width: 360, height: 270, ratio: Ratio.Ratio43 },
  large: { width: 209, height: 157, ratio: Ratio.Ratio43 },
  medium: { width: 209, height: 157, ratio: Ratio.Ratio43 },
  small: { width: 144, height: 108, ratio: Ratio.Ratio43 },
  XSmall: { width: 144, height: 108, ratio: Ratio.Ratio43 },
} as const satisfies ViewportToImageDimensions;

const LOGO_V5 = {
  tv1080p: { width: 92, height: 69, ratio: Ratio.Ratio43 },
  tv720p: { width: 92, height: 69, ratio: Ratio.Ratio43 },
  XLarge: { width: 92, height: 69, ratio: Ratio.Ratio43 },
  large: { width: 92, height: 69, ratio: Ratio.Ratio43 },
  medium: { width: 60, height: 45, ratio: Ratio.Ratio43 },
  small: { width: 60, height: 45, ratio: Ratio.Ratio43 },
  XSmall: { width: 60, height: 45, ratio: Ratio.Ratio43 },
} as const satisfies ViewportToImageDimensions;

const TILES_ICON = {
  XLarge: { width: 60, height: 60 },
  large: { width: 60, height: 60 },
  medium: { width: 60, height: 60 },
  small: { width: 60, height: 60 },
  XSmall: { width: 60, height: 60 },
} as const satisfies ViewportToImageDimensions;

const CHANNELS_GRID_IMAGE = {
  XLarge: { width: 300, height: 225, ratio: Ratio.Ratio43 },
  large: { width: 300, height: 225, ratio: Ratio.Ratio43 },
  medium: { width: 197, height: 148, ratio: Ratio.Ratio43 },
  small: { width: 197, height: 148, ratio: Ratio.Ratio43 },
  XSmall: { width: 197, height: 148, ratio: Ratio.Ratio43 },
} as const satisfies ViewportToImageDimensions;

const PROGRAMS_HIGHLIGHT = {
  XLarge: { width: 1824, height: 1025, ratio: Ratio.Ratio169 },
  large: { width: 1280, height: 720, ratio: Ratio.Ratio169 },
  medium: { width: 768, height: 432, ratio: Ratio.Ratio169 },
  small: { width: 700, height: 393, ratio: Ratio.Ratio169 },
  XSmall: { width: 375, height: 211, ratio: Ratio.Ratio169 },
} as const satisfies ViewportToImageDimensions;

const SHOWCASE_IMAGE = {
  XLarge: { width: 2000, height: 750, ratio: Ratio.Ratio166 },
  large: { width: 1600, height: 600, ratio: Ratio.Ratio166 },
  medium: { width: 1300, height: 487, ratio: Ratio.Ratio166 },
  small: { width: 1000, height: 375, ratio: Ratio.Ratio166 },
  XSmall: { width: 700, height: 262, ratio: Ratio.Ratio166 },
} as const satisfies ViewportToImageDimensions;

const SHOWCASE_IMAGE_WALL = {
  [Ratio.Ratio34]: {
    XLarge: { width: 500, height: 662 },
    large: { width: 404, height: 535 },
    medium: { width: 318, height: 421 },
    small: { width: 232, height: 307 },
    XSmall: { width: 170, height: 225 },
  },
  [Ratio.Ratio169]: {
    XLarge: { width: 500, height: 278 },
    large: { width: 404, height: 225 },
    medium: { width: 318, height: 177 },
    small: { width: 232, height: 129 },
    XSmall: { width: 170, height: 95 },
  },
} as const satisfies Record<
  Ratio.Ratio34 | Ratio.Ratio169,
  ViewportToImageDimensions
>;

const STUB_IMAGE = {
  tv1080p: { width: 140, height: 140 },
  tv720p: { width: 140, height: 140 },
  XLarge: { width: 140, height: 140 },
  large: { width: 140, height: 140 },
  medium: { width: 140, height: 140 },
  small: { width: 140, height: 140 },
  XSmall: { width: 140, height: 140 },
} as const satisfies ViewportToImageDimensions;

const USER_MENU_IMAGE = {
  XLarge: { width: 26, height: 26 },
  large: { width: 26, height: 26 },
  medium: { width: 26, height: 26 },
  small: { width: 26, height: 26 },
  XSmall: { width: 26, height: 26 },
} as const satisfies ViewportToImageDimensions;

const ACTION_SUCCESS = {
  XLarge: { width: 160, height: 160 },
  large: { width: 160, height: 160 },
  medium: { width: 160, height: 160 },
  small: { width: 160, height: 160 },
  XSmall: { width: 160, height: 160 },
} as const satisfies ViewportToImageDimensions;

const CREATIVEMEDIA_PICTURE = {
  XLarge: { width: 752 },
  large: { width: 752 },
  medium: { width: 752 },
  small: { width: 736 },
  XSmall: { width: 375 },
} as const satisfies ViewportToImageDimensions;

const CREATIVEMEDIA_CARD = {
  XLarge: { width: 220, height: 165 },
  large: { width: 182, height: 137 },
  medium: { width: 153, height: 115 },
  small: { width: 153, height: 115 },
  XSmall: { width: 144, height: 108 },
} as const satisfies ViewportToImageDimensions;

const CREATIVEMEDIA_COVER = {
  XLarge: { width: 1728, height: 969 },
  large: { width: 1440, height: 810 },
  medium: { width: 1024, height: 576 },
  small: { width: 800, height: 450 },
  XSmall: { width: 375, height: 211 },
} as const satisfies ViewportToImageDimensions;

const PROMOTION_BANNER = {
  tv1080p: { width: 1920, height: 720 },
  tv720p: { width: 1280, height: 480 },
  XLarge: { width: 1920, height: 720 }, // 16:6
  large: { width: 1260, height: 472 },
  medium: { width: 960, height: 360 },
  small: { width: 690, height: 920 }, // 3:4
  XSmall: { width: 320, height: 427 },
} as const satisfies ViewportToImageDimensions;

const PROMOTION_COVER = {
  tv1080p: { width: 1920, height: 960 },
  tv720p: { width: 1280, height: 640 },
  XLarge: { width: 1920, height: 840 }, // 16:7
  large: { width: 1260, height: 552 },
  medium: { width: 960, height: 420 },
  small: { width: 690, height: 1150 }, // 3:5
  XSmall: { width: 320, height: 533 },
} as const satisfies ViewportToImageDimensions;

const DEFAULT_SLIDE = {
  tv1080p: { width: 1920, height: 1080 },
  tv720p: { width: 1280, height: 720 },
  XLarge: { width: 768, height: 576 },
  large: { width: 768, height: 576 },
  medium: { width: 768, height: 576 },
  small: { width: 600, height: 1339 },
  XSmall: { width: 419, height: 935 },
} as const satisfies ViewportToImageDimensions;

export const DIMENSIONS = {
  [Ratio.Ratio166]: RATIO_166,
  [Ratio.Ratio169]: RATIO_169,
  [Ratio.Ratio23]: RATIO_23,
  [Ratio.Ratio34]: RATIO_34,
  [Ratio.Ratio43]: RATIO_43,
  [Ratio.Ratio47]: RATIO_47,
  [Ratio.Square]: RATIO_SQUARE,
  BANNER,
  CARROUSEL,
  CHANNELS_GRID_IMAGE,
  COVER_DETAIL_V5,
  COVER_IMAGE,
  CREATIVEMEDIA_PICTURE,
  CREATIVEMEDIA_CARD,
  CREATIVEMEDIA_COVER,
  DETAIL_LIGHT,
  DEFAULT_SLIDE,
  HEADER_LOGO,
  LIVE_GRID_CARD,
  LIST_EPISODES,
  LOGO_CHANNEL,
  LOGO_CHANNELS_PROGRAM_GUIDE,
  LOGO_TITLE_V5,
  LOGOTYPE_IDENTITY_V5,
  LOGO_CHANNEL_IDENTITY_V5,
  LOGO_V5,
  MULTI_LIVE_SETUP,
  OFFER_HIGHLIGHT,
  PROFILE_AVATAR,
  PROGRAMS_HIGHLIGHT,
  PROMOTION_BANNER,
  PROMOTION_COVER,
  SHOWCASE,
  SHOWCASE_IMAGE,
  SHOWCASE_IMAGE_WALL,
  STUB_IMAGE,
  TILES_ICON,
  USER_MENU_IMAGE,
  ACTION_SUCCESS,
} as const;

/**
 * Settings used when requesting images to our Thumbor server
 *
 * @see {@link https://thumbor.readthedocs.io/en/latest/quality.html Thumbor docs - quality}
 * @see {@link https://gitlab.canalplus.pro/deddev/thumbor/-/blob/master/infra/local/thumbor/conf/thumbor-http.conf Thumbor server configuration - ALLOWED_SOURCE}
 */
export const THUMBOR = {
  URLS_ALREADY_INCLUDING_ALLOWED_SOURCE: ['thumb', 'canal.vct.pgw.orange.fr'],
  QUALITY_DEFAULT: 80,
} as const;

const VIEWPORTS_MOBILE_ASCENDING = [
  { viewport: 'XSmall', device: 'mobile', mediaQuery: '(max-width: 419px)' },
  { viewport: 'small', device: 'mobile', mediaQuery: '(max-width: 699px)' },
] as const satisfies DiveViewportsWithDevice;

const VIEWPORTS_DESKTOP_ASCENDING = [
  { viewport: 'medium', device: 'tablet', mediaQuery: '(max-width: 769px)' },
  { viewport: 'large', device: 'laptop', mediaQuery: '(max-width: 1440px)' },
  { viewport: 'XLarge', mediaQuery: '(min-width: 1441px)' },
] as const satisfies DiveViewportsWithDevice;

const VIEWPORTS_DEFAULT_SLIDE = [
  { viewport: 'XSmall', device: 'mobile', mediaQuery: '(max-width: 419px)' },
  { viewport: 'small', device: 'mobile', mediaQuery: '(max-width: 600px)' },
  { viewport: 'medium', device: 'tablet', mediaQuery: '(max-width: 769px)' },
  { viewport: 'large', device: 'laptop', mediaQuery: '(max-width: 1440px)' },
  { viewport: 'XLarge', mediaQuery: '(min-width: 1441px)' },
] as const satisfies DiveViewportsWithDevice;

export const VIEWPORTS = {
  /**
   * myCanal Viewports and media-query settings for **Mobile** devices
   */
  MOBILE_ASCENDING: VIEWPORTS_MOBILE_ASCENDING,
  /**
   * myCanal Viewports and media-query settings for **Desktop** devices
   */
  DESKTOP_ASCENDING: VIEWPORTS_DESKTOP_ASCENDING,
  /**
   * myCanal Viewports and media-query settings
   */
  ASCENDING: [...VIEWPORTS_MOBILE_ASCENDING, ...VIEWPORTS_DESKTOP_ASCENDING],
  DEFAULT_SLIDE: VIEWPORTS_DEFAULT_SLIDE,
} satisfies Record<
  'MOBILE_ASCENDING' | 'DESKTOP_ASCENDING' | 'ASCENDING' | 'DEFAULT_SLIDE',
  DiveViewportsWithDevice
>;
