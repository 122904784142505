import { logoutUser } from '@canalplus/ifc-onecore';
import { removeCookie } from '@canalplus/mycanal-commons';
import { getLogoutUrl } from '@canalplus/sdk-pass';
import { usePassSdk } from '@canalplus/sdk-pass-react';
import { CookieKey } from '../../constants/cookie';
import { useInvariantSelector } from '../../helpers/hooks/useInvariantSelector';
import { removeAllProfiles } from '../../services/profiles/profileStorageService';
import {
  offerLocationSelector,
  offerZoneSelector,
} from '../../store/slices/application-selectors';
import { isTim } from '../application/application-helper';
import { getLogoutTargetUrl } from './getLogoutTargetUrl';

export const useLogout = (): (() => void) => {
  const logoutUrl = usePassSdk(getLogoutUrl);

  const offerZone = useInvariantSelector(offerZoneSelector);
  const offerLocation = useInvariantSelector(offerLocationSelector);

  return () => {
    removeAllProfiles();
    removeCookie(CookieKey.PassId);
    removeCookie(CookieKey.PPassToken);

    if ($_BUILD_RENDERMODE_CSR) {
      logoutUser();
      return;
    }

    const targetUrl = getLogoutTargetUrl(offerLocation);
    const zone = isTim() ? offerZone : offerLocation;

    window.location.assign(logoutUrl({ targetUrl, zone }));
  };
};
