import { addHeader } from '@canalplus/mycanal-fetch';
import type { HodorApiParameter, HodorSdkConfig } from '../config/types';
import { fetchHodor } from '../helpers/fetchHodor';
import type { FetchHodorParameters } from '../helpers/types';

export type RequestApiParameters = {
  /** For log purpose */
  apiName: FetchHodorParameters['api'];

  hodorApiParameter: HodorApiParameter[];
  method: 'PUT' | 'DELETE' | 'POST';
  url: string;
  body?: BodyInit;
};

/**
 * Request an Hodor Api that:
 * - need a **Content-Type: 'application/json'** header
 * - do not returning data
 */
export async function requestApi(
  hodorSdkConfig: HodorSdkConfig,
  { apiName, body, hodorApiParameter, method, url }: RequestApiParameters,
): Promise<void> {
  const startAt = Date.now();

  await fetchHodor({
    api: apiName,
    body,
    hodorSdkConfig: {
      ...hodorSdkConfig,
      fetchOptions: {
        ...hodorSdkConfig.fetchOptions,
        headers: addHeader(
          hodorSdkConfig.fetchOptions?.headers,
          'Content-Type',
          'application/json',
        ),
        method,
      },
    },
    hodorSdkParameters: hodorApiParameter,
    startAt,
    url,
  });
}
