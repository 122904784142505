import { getUrlOrigin } from '@canalplus/mycanal-commons';
import { Template } from '@canalplus/sdk-hodor';
import type { ApiV2WsFromPath } from '@dce-front/hodor-types';

/** Wether or not we should force a 301 redirect */
export const shouldDifferentPathRedirect = (
  path: string,
  redirectTo: ApiV2WsFromPath['redirectTo'],
): boolean => {
  const { displayTemplate, path: redirectToPath } = redirectTo;

  const isTemplateError = displayTemplate === Template.Error;
  const isTemplateLiveTV = displayTemplate === Template.LiveTv;

  return (
    getUrlOrigin(decodeURIComponent(path)) !==
      getUrlOrigin(decodeURIComponent(redirectToPath)) &&
    !isTemplateError &&
    !isTemplateLiveTV
  );
};
