import { getEncodedQueryString } from '@canalplus/mycanal-commons';
import type { PassSdkConfig } from '../config/types';
import { paths } from '../constants';
import { PassError } from '../errors/PassError';
import { fetchPass } from '../helpers/private/fetchPass';
import { getFilledPath } from '../helpers/private/getFilledPath';
import type { PassUserData } from '../types/PassUserData';

const api = 'updateUserConsent';
const route = 'updateUserConsent';

export type UpdateUserConsentParameters = {
  /** User's Pass token */
  passToken: string;

  /** User settings for recommendations, ads, analytics and anonymous tracking */
  settings: Partial<
    Pick<
      PassUserData,
      'reco' | 'trackingPub' | 'analytics' | 'anonymousTracking'
    >
  >;
};

/**
 * Allows to update GDPR requirements by calling {@link https://canal-plus.atlassian.net/wiki/spaces/PASS/pages/161846532/updateUserConsent+public Pass **updateUserConsent** API}
 *
 * @throws an error if no user data is available or if the **updateUserConsent** API returns an error code different from '0'
 */
export const updateUserConsent = async (
  passSdkConfig: PassSdkConfig,
  { passToken, settings }: UpdateUserConsentParameters,
): Promise<PassUserData> => {
  const { baseUrl, offerZone, offerLocation, portailId, media, logger, vect } =
    passSdkConfig;

  const url = `${baseUrl.pass}${getFilledPath({
    offerLocation,
    offerZone,
    path: paths.updateUserConsent,
  })}`;

  const response = await fetchPass({
    api,
    passSdkConfig: {
      ...passSdkConfig,
      fetchOptions: {
        ...passSdkConfig.fetchOptions,
        method: 'POST',
        headers: {
          ...passSdkConfig.fetchOptions?.headers,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: getEncodedQueryString({
          passToken,
          portailId,
          media,
          vect,
          ...settings,
        }).substring(1),
      },
    },
    route,
    url,
  });

  const content = await response.json();

  logger.debug(`PASS ${api} Response:`, content);

  // `errorCode = 0` means that Pass request succeeded
  if (content.response.errorCode !== 0) {
    throw new PassError(
      `PASS ${api} Error while updating user consent`,
      response.status,
      content.response.errorCode,
    );
  }

  return content.response.userData;
};

/**
 * Allows to update a user's consent by calling Pass **updateUserConsent** API
 *
 * @alias
 * @deprecated Use `updateUserConsent` instead
 */
export const updateProfile = updateUserConsent;
