import { ProfileModal } from '@canalplus/mycanal-commons';
import { createSelector } from '@reduxjs/toolkit';
import type { ProfilesModal } from '../../store/slices/profilesModal';
import type { IState } from '../../store/types/State-type';

const profilesModalSelector = (state: IState): ProfilesModal =>
  state?.profilesModal;

export const modalCurrentViewSelector = createSelector(
  profilesModalSelector,
  (modal) => modal?.currentView ?? ProfileModal.ProfileListView,
);
export const modalIsFromLandingSelector = createSelector(
  profilesModalSelector,
  (modal) => modal?.isFromLanding,
);

export const selectedProfileSelector = createSelector(
  profilesModalSelector,
  (modal) => modal?.selectedProfile,
);

export const isKidsProfileSettingsSelector = createSelector(
  profilesModalSelector,
  (modal) => modal?.isKidsProfileSettings || false,
);

export const profileInputSelector = createSelector(
  profilesModalSelector,
  (modal) => modal?.profileInput,
);

export const avatarSelector = createSelector(
  profilesModalSelector,
  (modal) => modal?.avatar,
);
