import loadable from '@loadable/component';
import Spinner from '../../Spinner/Spinner';

const LoadableUserSettingsModal = loadable(
  () => import('./UserSettingsModal'),
  {
    fallback: <Spinner />,
  },
);

export default LoadableUserSettingsModal;
