import { isSomeEnum } from '@canalplus/mycanal-commons';
import { Template } from '@canalplus/sdk-hodor';
import type { ApiV2Context } from '@dce-front/hodor-types/api/v2/common/dto/definitions';
import type { ApiV2PageStrate } from '@dce-front/hodor-types/api/v2/page/dtos/definitions';
import type {
  ApiV2PageStratePromotion,
  ApiV2PromotionContentStrate,
} from '@dce-front/hodor-types/api/v2/page/dtos/strates/promotion/definitions';

import { getBestVideoContent } from '../../../../../components/Video/helpers/video-helper';
import {
  getLocationStateContext,
  setContextForOnClick,
} from '../../../../../helpers/contents/contents-helper';
import { INFO_DISPLAY_MODE, PROMOTION_STRATE_TYPES } from '../constants';
import type { PromotionStateTypes } from '../types';
import type {
  ButtonData,
  PromotionStrateContentCatchlines,
  PromotionStrateData,
} from './types';

const isTemplate = isSomeEnum(Template);

const isStratePromotion = (
  strate?: ApiV2PageStrate,
): strate is ApiV2PageStratePromotion =>
  strate?.type === PROMOTION_STRATE_TYPES.cover ||
  strate?.type === PROMOTION_STRATE_TYPES.banner;

const formatCatchlines = (
  catchlines?: PromotionStrateContentCatchlines,
): NonNullable<PromotionStrateContentCatchlines> => {
  if (!catchlines) {
    return [];
  }

  const filteredCatchlines = catchlines.filter(
    (catchline) => catchline.label && catchline.type,
  );
  const formattedCatchlines: PromotionStrateContentCatchlines = [];

  filteredCatchlines.forEach((catchline) => {
    String(catchline.label)
      .split('\n')
      .forEach((label) => {
        formattedCatchlines.push({
          type: catchline.type,
          label,
        });
      });
  });

  return formattedCatchlines;
};

const getFormatButtonWithContext = (
  button: ApiV2PromotionContentStrate['button'],
): ButtonData | undefined => {
  const onClick = button?.onClick as ApiV2PromotionContentStrate['button'] & {
    trackingContext?: ApiV2Context;
  }; // trackingContext doesn't exist in Hodor typing but the data is received
  if (!onClick) {
    return;
  }

  return setContextForOnClick({
    ...button,
    onClick: {
      ...onClick,
      contentID: 'contentID' in onClick ? onClick.contentID : undefined, // Button action: display the player with a content
      EpgId: 'EpgId' in onClick ? onClick.EpgId : undefined, // Button action: display a live channel
      context:
        'displayTemplate' in onClick && isTemplate(onClick.displayTemplate)
          ? getLocationStateContext(onClick.displayTemplate)
          : undefined,
      trackingContext:
        'displayMode' in onClick && onClick.displayMode === Template.Fullscreen
          ? {
              ...onClick?.trackingContext,
              page_name:
                'displayName' in onClick
                  ? `${onClick?.displayName} - Lecture Player`
                  : 'no display name - Lecture Player',
            } // Mandatory for tracking event when the button action is to open the main player
          : onClick?.trackingContext,
    },
  });
};

const getFormattedPromotionStrate = (
  strate?: ApiV2PageStrate,
  isIOS = false,
): PromotionStrateData | undefined => {
  if (!isStratePromotion(strate) || !strate.contents?.[0]) {
    return;
  }

  const content = strate.contents[0];
  const {
    altLogoChannel,
    altLogoType,
    altText,
    catchlines,
    contentID,
    cover,
    URLLogoChannel,
    URLLogoType,
    button,
  } = content;
  const formattedButtonWithContext = getFormatButtonWithContext(button);

  if (
    !contentID ||
    !cover?.regular?.URLImageDefault ||
    !formattedButtonWithContext ||
    !formattedButtonWithContext?.label
  ) {
    return;
  }

  const infoDisplayMode =
    !strate.displayParameters || !strate.displayParameters.infoDisplayMode
      ? INFO_DISPLAY_MODE.persistent
      : strate.displayParameters.infoDisplayMode;

  return {
    promotionType: strate.type as PromotionStateTypes,
    content: {
      contentID,
      isOutroOnly: infoDisplayMode === INFO_DISPLAY_MODE.outroOnly,
      altLogoChannel,
      URLLogoChannel,
      altLogoType,
      altText,
      URLLogoType,
      catchlines: formatCatchlines(catchlines),
      button: formattedButtonWithContext,
      media: {
        regular: {
          URLImageDefault: cover.regular.URLImageDefault,
          video: getBestVideoContent(cover.regular.videos, isIOS),
          videoOutro: getBestVideoContent(cover.regular.videosOutro, isIOS),
        },
        ...(cover.compact && {
          compact: {
            URLImageDefault:
              cover.compact.URLImageDefault || cover.regular.URLImageDefault,
            video: getBestVideoContent(cover.compact.videos, isIOS),
            videoOutro: getBestVideoContent(cover.compact.videosOutro, isIOS),
          },
        }),
      },
    },
  };
};

export default getFormattedPromotionStrate;
