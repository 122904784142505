import { Button } from '@canalplus/dive';
import classNames from 'classnames/bind';
import type { JSX } from 'react';
import type { OneDiscoveryTranslations } from '../../lang/types';

import { useTranslation } from '../../lang';
import styles from './SkipLink.css';

const cx = classNames.bind(styles);

export type SkipLinkProps = {
  /**
   * The anchor id of the DOM Element to navigate to.
   *
   * **To be prefixed with a `#`**
   *
   * @example
   * <SkipLink href="#abc" />
   * <div id="abc"/>
   */
  href?: string;
  /** Callback triggered when the skip link is clicked */
  onClick?: () => void;
  /** The key from the i18n `A11y` object that points to the text value needed as skip link label */
  label: keyof OneDiscoveryTranslations['A11y'];
  className?: string;
  ariaLabel?: string;
};

/**
 * Skip links help users quickly move past blocks of content they do not want to horizontally keyboard-navigate through.
 *
 * It needs to be focused to be visible. It renders as two different HTML elements:
 *
 * - `<a>` when passed an `href` in props.
 * - `<button>` by default.
 *
 * @example
 * // 1. focus & click
 * <SkipLink href="#2" label="nextCategory" />
 * <ContentRowWithManyChildren id="1" />
 * // 2. focus jumps here when SkipLink is focused and clicked
 * <ContentRowWithManyChildren id="2" />
 */
function SkipLinkWeb({
  href,
  label,
  onClick,
  className,
  ariaLabel,
}: SkipLinkProps): JSX.Element | null {
  const { t } = useTranslation();
  return (
    <Button
      variant="slim"
      width="fit"
      font="hind"
      onClick={onClick}
      className={cx('SkipLink', className)}
      renderWrapper={
        href
          ? ({ children, ref, ...props }) => {
              return (
                <a {...props} href={href}>
                  {children}
                </a>
              );
            }
          : undefined
      }
      aria-label={ariaLabel}
    >
      {t(`A11y.${label}`)}
    </Button>
  );
}

function SkipLinkTv(): JSX.Element | null {
  return null;
}

const SkipLink = $_BUILD_RENDERMODE_CSR ? SkipLinkTv : SkipLinkWeb;

export default SkipLink;
