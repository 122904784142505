import { PROFILES_LIMIT, ProfileModal } from '@canalplus/mycanal-commons';
import {
  ModalV2,
  ModalV2Size,
  VirtualKeyboardProvider,
} from '@canalplus/mycanal-sharedcomponent';
import {
  KEY_BACK,
  Layer,
  useKeyCatcher,
  useStore as useStoreNavigation,
} from '@canalplus/one-navigation';
import type { ApiV2NavigationSettings } from '@dce-front/hodor-types/api/v2/authenticate/definitions';
import type { ApiV2Profile } from '@dce-front/hodor-types/api/v2/me/profiles/definitions';
import type { JSX } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useSelector, useStore } from 'react-redux';
import { ThemeColor } from '../../../../constants/themeColor';
import { ProfileEvents, ProfileType } from '../../../../constants/tracking';
import { isPointerVisible } from '../../../../helpers/binder/pointer';
import { setTokenCMS } from '../../../../helpers/cookie/cookie-helper';
import { useAppHistory } from '../../../../helpers/hooks/reactRouter';
import { useAppDispatch } from '../../../../helpers/hooks/useAppDispatch';
import Logger from '../../../../helpers/logger/logger-helper';
import { LAYER_SETTINGS_MANAGE_PROFILES } from '../../../../helpers/oneNavigation/layers';
import { sendProfileTrackingEvent } from '../../../../helpers/tracking/tracking-helper';
import {
  getRedirectPathOnWhoIsWatchingProfile,
  getRedirectUrlOnSelectProfile,
} from '../../../../helpers/url/url-helper';
import { performAuthenticate } from '../../../../services/Hodor/Authenticate/performAuthenticate';
import { setCurrentProfile } from '../../../../services/profiles/profileStorageService';
import { setApplicationHodorSdkConfig } from '../../../../store/slices/application';
import {
  hodorSdkConfigSelector,
  kidsHomeUrlSelector,
  offerLocationSelector,
  userSelector,
} from '../../../../store/slices/application-selectors';
import { pageURLSelector } from '../../../../store/slices/page-selectors';
import {
  setProfileView,
  setSelectedProfile,
  wipeProfileInfos,
} from '../../../../store/slices/profilesModal';
import {
  modalCurrentViewSelector,
  selectedProfileSelector,
} from '../../../../store/slices/profilesModal-selectors';
import {
  displayProfileMenu,
  userListProfile,
  userListProfiles,
} from '../../../../store/slices/user';
import {
  profilesSelector,
  segTypeSelector,
} from '../../../../store/slices/user-selectors';
import type { IState } from '../../../../store/types/State-type';
import LoadableMaxProfilesReachedModal from '../../../Modal/MaxProfilesReachedModal/LoadableMaxProfilesReachedModal';
import ProfilesViewHandler from '../../ProfilesViewHandler';
import { getPrevProfilesView } from '../helpers';
import styles from './ProfileModalSelectorMyCanal.css';

export type UseProfileSelector = {
  /**
   * Action to use when click on a profile
   * On a profile click, we need to replace the current profile in the localStorage
   * And we need to reload the application to fetch all the perso strates for
   * the newly selected profile
   * @param profileSelectedArg The profile clicked
   * @param event React.MouseEvent
   */
  handleProfileClick: (
    profileSelectedArg: ApiV2Profile,
    event: React.MouseEvent,
  ) => void;
  /**
   * Action to use when click on a profile for the screen whoIsWatching
   * On a profile click from onBoarding select profile (Who is Watching screen),
   * We need to replace the current profile in the localStorage and in store
   * And we need to call authenticate and redirect to the good path
   * @param profileSelectedArg The profile clicked
   * @param event React.MouseEvent
   */
  handleProfileClickWithAuth: (
    profileSelectedArg: ApiV2Profile,
    event: React.MouseEvent,
  ) => Promise<void>;
  /**
   * Action to use when click on add a profile
   */
  handleAddProfileClick: () => void;
  /**
   * Action to use when click on edit a profile
   */
  handleEditButton: () => void;
  /**
   * Render the JSX Elements for the modals
   */
  renderModals: () => JSX.Element;
  /**
   * Force to close the profiles modal
   */
  closeProfilesModal: () => void;
};

/**
 * Hook get the actions for adding and editing a profile and a renderer for modals associated. It returns :
 * - The action when click on a profile
 * - The action when click add a profile
 * - The action when click edit a profile
 * - The modals renderer to edit or adding profile
 * @param binderLayerFrom Binder Layer we come from when we use this hook.
 * It's to restore focus on this layer when we close popins of profiles selector
 */
export const useProfileSelector = (
  binderLayerFrom: number,
): UseProfileSelector => {
  const dispatch = useAppDispatch();
  const history = useAppHistory();

  const currentView: ProfileModal = useSelector(modalCurrentViewSelector);
  const profileSelected = useSelector(selectedProfileSelector);
  const store = useStore<IState>();

  const [showProfilesViewHandlerModal, setShowProfilesViewHandlerModal] =
    useState(false);
  const [showMaxProfilesReachedModal, setShowMaxProfilesReachedModal] =
    useState(false);

  const onUpdateCurrentProfile = useCallback(
    (
      profileSelectedArg: ApiV2Profile,
      event: React.MouseEvent,
      profileEvent: ProfileEvents,
      updateStore: boolean = false,
    ) => {
      const state = store.getState();
      const offerLocation = offerLocationSelector(state);
      const pageURL = pageURLSelector(state);
      const profiles = profilesSelector(state);
      const segType = segTypeSelector(state);

      // update cookies
      setCurrentProfile(profileSelectedArg);

      // Update in redux store the selected profile and the list sorted
      if (updateStore) {
        dispatch(userListProfile(profileSelectedArg));
        dispatch(userListProfiles(profiles));
      }

      const { profileId } = profileSelectedArg || {};
      const profileType = profileSelectedArg.isKidsProfile
        ? ProfileType.Kids
        : ProfileType.Standard;

      sendProfileTrackingEvent({
        activeProfiles: profiles.length,
        event,
        isKids: !!profileSelectedArg.isKidsProfile,
        offerLocation,
        pageURL,
        profileId: profileId?.toString(),
        profileType,
        segType,
        themeColor: ThemeColor.Dark,
        type: profileEvent,
        version: $_BUILD_APP_VERSION,
      });
    },
    [dispatch, store],
  );

  const handleProfileClick = useCallback(
    async (profileSelectedArg: ApiV2Profile, event: React.MouseEvent) => {
      const state = store.getState();
      const offerLocation = offerLocationSelector(state);
      const kidsHomeUrl = kidsHomeUrlSelector(state);

      onUpdateCurrentProfile(profileSelectedArg, event, ProfileEvents.Change);
      const { pathname } = history.location;

      const redirectPathOnSelectProfile = getRedirectUrlOnSelectProfile(
        offerLocation,
        pathname,
        (profileSelectedArg.isKidsProfile && kidsHomeUrl) || undefined,
      );

      if (redirectPathOnSelectProfile) {
        window.location.href = redirectPathOnSelectProfile;
      }
    },
    [onUpdateCurrentProfile, history, store],
  );

  const handleProfileClickWithAuth = useCallback(
    async (profileSelectedArg: ApiV2Profile, event: React.MouseEvent) => {
      try {
        const state = store.getState();
        const offerLocation = offerLocationSelector(state);
        const hodorSdkConfig = hodorSdkConfigSelector(state);
        const userData = userSelector(state);

        onUpdateCurrentProfile(
          profileSelectedArg,
          event,
          ProfileEvents.Select,
          true,
        );

        if (!hodorSdkConfig) {
          throw new Error('hodorSdkConfig is undefined');
        }

        const configWithInit = {
          ...hodorSdkConfig,
          profileId: profileSelectedArg.profileId?.toString(),
        };

        // Cass hodor authenticate with the selected profile info
        const {
          authenticateResponse,
          hodorSdkConfig: hodorSdkConfigFromAuthenticate,
        } = await performAuthenticate({
          configWithInit,
          store,
          isKidsProfile: profileSelectedArg.isKidsProfile,
          shouldCollectUserData: userData.settings.hasUserDataCollected,
        });

        dispatch(setApplicationHodorSdkConfig(hodorSdkConfigFromAuthenticate));

        // Define the tokenCMS cookie
        // Needed for Akamai Cache
        if (authenticateResponse) {
          setTokenCMS(authenticateResponse.token);
        }

        // Redirect to the good path
        // if the selected profile is kids, redirect to the home kids
        const { kidsHomeUrl: kidsUrlFromAuth } =
          (authenticateResponse.settings as ApiV2NavigationSettings & {
            kidsHomeUrl: string;
          }) || {};

        const { location } = history;

        const redirectPath = getRedirectPathOnWhoIsWatchingProfile(
          offerLocation,
          location.pathname,
          (profileSelectedArg.isKidsProfile && kidsUrlFromAuth) || undefined,
        );

        // Need manually update location because the router is not yet instantiate, history.replace() has no effect
        // TODO need tests
        location.pathname = redirectPath;
        location.state = { ...location.state, fromSSR: false };
      } catch (error) {
        Logger.error(`ProfileSelector > Authenticate error ${error}`);
      }
    },
    [history, onUpdateCurrentProfile, store, dispatch],
  );

  const handleAddProfileClick = useCallback(() => {
    const state = store.getState();
    const profiles = profilesSelector(state);

    if (profiles.length < PROFILES_LIMIT) {
      dispatch(wipeProfileInfos());
      dispatch(setSelectedProfile());
      dispatch(setProfileView(ProfileModal.ProfileCreationViewFromLanding));
      setShowProfilesViewHandlerModal(true);
      dispatch(displayProfileMenu(false));
    } else {
      setShowMaxProfilesReachedModal(true);
    }
  }, [store, dispatch]);

  const handleEditButton = () => {
    dispatch(setProfileView(ProfileModal.ProfileListView));
    setShowProfilesViewHandlerModal(true);
  };

  const profilesViewHandlerModalSize = $_BUILD_RENDERMODE_CSR
    ? ModalV2Size.FullScreen
    : undefined;

  const hasPreviousProfileView = getPrevProfilesView(
    currentView,
    profileSelected,
  );

  /**
   * On TV, if the user use a back navigation,
   * we need to display the userMenu by closing the modal in 2 cases:
   *
   * - when the user is creating a profile and he clicks on the back button
   * => we don't want to display the <ProfilesList> on TV (on desktop, the <ProfilesList>t is displayed)
   *
   * - when the user is on the <ProfilesList> and he clicks on the back button
   * => we want to close the modal and display the <SectionsListTemplate> (userMenu on TV)
   *
   */
  const shouldCloseModal =
    ((currentView === ProfileModal.ProfileCreationView ||
      currentView === ProfileModal.ProfileCreationViewFromLanding) &&
      hasPreviousProfileView === ProfileModal.ProfileListView) ||
    (currentView === ProfileModal.ProfileListView &&
      hasPreviousProfileView === undefined) ||
    currentView === ProfileModal.ProfileCreationViewFromLanding;

  const storeNavigation = useStoreNavigation();

  useEffect(() => {
    if (showProfilesViewHandlerModal) {
      storeNavigation.setActiveLayer(LAYER_SETTINGS_MANAGE_PROFILES);
      storeNavigation.focusDefault();
    } else {
      storeNavigation.setActiveLayer(binderLayerFrom);
    }
  }, [showProfilesViewHandlerModal, storeNavigation, binderLayerFrom]);

  useEffect(() => {
    if ($_BUILD_RENDERMODE_CSR && isPointerVisible()) {
      const container =
        document.querySelector('#modal-scroll-container') || window;

      container.scrollTo(0, 0);
    }
  }, [currentView, storeNavigation]);

  const handleBack = useCallback(() => {
    if ($_BUILD_RENDERMODE_CSR) {
      return shouldCloseModal
        ? setShowProfilesViewHandlerModal(false)
        : dispatch(setProfileView(hasPreviousProfileView));
    }

    return hasPreviousProfileView
      ? dispatch(setProfileView(hasPreviousProfileView))
      : setShowProfilesViewHandlerModal(false);
  }, [dispatch, hasPreviousProfileView, shouldCloseModal]);

  useKeyCatcher(KEY_BACK, handleBack, LAYER_SETTINGS_MANAGE_PROFILES);

  const renderModals = () => {
    return (
      <>
        {showProfilesViewHandlerModal && (
          <VirtualKeyboardProvider>
            <ModalV2
              onClose={() => setShowProfilesViewHandlerModal(false)}
              onBack={hasPreviousProfileView && (() => handleBack())}
              isTvDevice={$_BUILD_RENDERMODE_CSR}
              size={profilesViewHandlerModalSize}
              type="profile"
              hidePadding={currentView === ProfileModal.AvatarSelectionView}
              portalId="modal-scroll-container"
              id="modal-scroll-container"
              {...(currentView === ProfileModal.AvatarSelectionView && {
                className:
                  styles[
                    'ProfileModalSelectorMyCanal__profilesModal--no-scrollBar'
                  ],
              })}
            >
              <Layer layer={LAYER_SETTINGS_MANAGE_PROFILES}>
                <ProfilesViewHandler
                  setOpenModal={setShowProfilesViewHandlerModal}
                />
              </Layer>
            </ModalV2>
          </VirtualKeyboardProvider>
        )}

        {showMaxProfilesReachedModal && (
          <ModalV2 isTvDevice={$_BUILD_RENDERMODE_CSR} size="medium">
            <LoadableMaxProfilesReachedModal
              setOpenMaxProfilesReachedModal={setShowMaxProfilesReachedModal}
            />
          </ModalV2>
        )}
      </>
    );
  };

  return {
    handleProfileClick,
    handleProfileClickWithAuth,
    handleAddProfileClick,
    handleEditButton,
    renderModals,
    closeProfilesModal: () => {
      setShowProfilesViewHandlerModal(false);
    },
  };
};
