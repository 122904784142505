import { fetch } from '@canalplus/mycanal-fetch';
import type { LogApiMetadata } from '@canalplus/mycanal-logger';
import { Service } from '@canalplus/mycanal-logger';
import type { GetPurchaseCodeStatusResponse } from '@canalplus/types-acm';
import type { AcmSdkConfig } from '../config/types';
import type { AcmApi } from '../constants/paths';
import { AcmError } from '../error/AcmError';
import { getFilledApiUrl } from '../helpers/getFilledApiUrl';
import { isAcmApiError } from '../helpers/isAcmApiError';
import { logError } from '../helpers/logError';

/** @category Function parameters */
export type GetPurchaseCodeStatusParameters = AcmSdkConfig & {
  passToken: string;
};

/** Fetch **getPurchaseCodeStatus** ACM Api */
export async function getPurchaseCodeStatus({
  endpoint,
  fetchOptions,
  offerLocation,
  offerZone,
  passToken,
  appKeyLog,
  requestId,
  logMetadata,
  logger,
}: GetPurchaseCodeStatusParameters): Promise<GetPurchaseCodeStatusResponse> {
  const startAt = Date.now();
  const api: AcmApi = 'getPurchaseCodeStatus';

  const url = getFilledApiUrl({
    api,
    endpoint,
    offerLocation,
    offerZone,
  });

  const baseErrorLogs = {
    ...logMetadata,
    endpoint: url,
    offerLocation,
    offerZone,
    route: api,
    startAt,
    req: { appData: { appKey: appKeyLog }, ...(requestId && { requestId }) },
  } satisfies LogApiMetadata;

  try {
    const getPurchaseCodeStatusResponse = await fetch(url, {
      ...fetchOptions,
      headers: {
        ...fetchOptions?.headers,
        passToken,
        'Content-Type': 'application/json',
      },
    });

    logger.info(
      `ACM ${api} ${getPurchaseCodeStatusResponse.status}`,
      logger.generateApiMetadata(Service.Acm, api, {
        ...baseErrorLogs,
        statusCode: getPurchaseCodeStatusResponse.status,
      }),
    );

    const purchaseCodeStatus = await getPurchaseCodeStatusResponse.json();

    if (getPurchaseCodeStatusResponse.ok) {
      if (getPurchaseCodeStatusResponse.status === 204) {
        throw new Error(
          `ACM ${api} ${getPurchaseCodeStatusResponse.status}, code does not exist`,
        );
      }

      return purchaseCodeStatus as GetPurchaseCodeStatusResponse;
    }

    if (isAcmApiError(purchaseCodeStatus)) {
      const { errorCode, errorDescription, statusCode } = purchaseCodeStatus;

      throw new AcmError(errorCode, errorDescription, statusCode);
    }

    throw new Error(`ACM ${api} ${getPurchaseCodeStatusResponse.status}`);
  } catch (error) {
    logError({ error, logger, baseErrorLogs, api });

    throw error;
  }
}
