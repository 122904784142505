import { KEY_ENTER, Store, type Key } from '@canalplus/one-navigation';
import type { History } from 'history';

export type FocusState = {
  binderId?: string;
  binderIndex: number;
  elementIndex: number;
};

export type HistoryFocusState = { focusState?: FocusState };

export class FocusManager {
  store: Store;
  private history?: History<HistoryFocusState>;
  private saveFocusState?: boolean;

  constructor(
    store: Store,
    history?: History<HistoryFocusState>,
    saveFocusState?: boolean,
  ) {
    this.store = store;
    this.history = history;
    this.saveFocusState = saveFocusState;

    this.initSaveFocusState();
  }

  private initSaveFocusState = () => {
    if (!this.saveFocusState || !this.history) {
      return;
    }

    this.store.addListener('key', this.handleKey);
  };

  private handleKey = (key: Key) => {
    if (key === KEY_ENTER) {
      this.handleSaveFocusState();
    }
  };

  private handleSaveFocusState = () => {
    const { currentBinder, current, binders } =
      this.store.getActiveLayer() || {};

    if (!currentBinder || !current) {
      return;
    }

    const binderId = currentBinder.id;
    let binderIndex = binders.indexOf(currentBinder);
    const elementIndex = currentBinder.getElements().indexOf(current);

    if (binderIndex === -1 || elementIndex === -1) {
      return;
    }

    if (binderId && binderId !== `strate-${binderIndex}`) {
      binderIndex = Math.max(
        binderIndex,
        Number(binderId.replace(/strate-/, '') ?? binderIndex),
      );
    }

    const focusState: FocusState = {
      binderId,
      binderIndex,
      elementIndex,
    };

    // Save focusState to history
    if (this.history) {
      this.history.location.state.focusState = focusState;
    }
  };

  getFocusState = () => {
    return this.history?.location.state?.focusState;
  };

  onFocusable = () => {
    const focusState = this.getFocusState();

    if (focusState) {
      const layer = this.store.getActiveLayer();

      const binder = focusState.binderId
        ? layer.getBinderById(focusState.binderId)
        : layer.binders[focusState.binderIndex];

      if (binder && focusState.elementIndex < binder.getElements().length) {
        binder.focusByIndex(focusState.elementIndex);
        return;
      }
    }

    this.store.focusDefault();
  };
}
