import { List, ToggleSwitch } from '@canalplus/dive';
import { goBack } from '@canalplus/ifc-onecore';
import {
  Binder,
  KEY_BACK,
  useKeyCatcher,
  useStore,
} from '@canalplus/one-navigation';
import classNames from 'classnames/bind';
import type { JSX } from 'react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../helpers/hooks/useAppDispatch';
import { usePreloadChunks } from '../../helpers/hooks/usePreloadChunks/usePreloadChunks';
import { useTracking } from '../../helpers/hooks/useTracking';
import {
  LAYER_PAGE,
  LAYER_WHOISWATCHING,
} from '../../helpers/oneNavigation/layers';
import { MIDDLEWARE_PROFILES_SELECTOR } from '../../helpers/oneNavigation/middleware';
import {
  setCookieWhoIsWatchingDisabled,
  setCookieWhoIsWatchingSession,
} from '../../helpers/whoIsWatching/cookieHelper';
import { useTranslation } from '../../lang';
import {
  setForceWhoIsWatching,
  setWhoIsWatchingDisabled,
  setWhoIsWatchingUserSession,
} from '../../store/slices/application';
import { getWhoIsWatchingSettingsSelector } from '../../store/slices/application-selectors';
import { hasProfilesLengthSelector } from '../../store/slices/user-selectors';
import Spinner from '../Spinner/Spinner';
import ProfileSelector from './ProfileSelector/ProfileSelector';
import styles from './WhoIsWatching.css';
import { TRACKING_ARGS_PAGE_WHOISWATCHING } from './constants';

const cx = classNames.bind(styles);
const WIW_BINDER_ID = 'wiwprofilebinder';

function WhoIsWatching(): JSX.Element | null {
  const isProfileSelectorRendered = useSelector(hasProfilesLengthSelector);
  const whoIsWatchingSettings = useSelector(getWhoIsWatchingSettingsSelector);
  const [isWhoIsWatchingDisabled, setIsWhoIsWatchingDisabled] = useState(
    whoIsWatchingSettings?.disabled,
  );
  const [spinnerVisible, setSpinnerVisible] = useState(false);

  const storeOneNavigation = useStore();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { sendTracking } = useTracking();

  // send tracking on page mount
  useEffect(() => {
    sendTracking(TRACKING_ARGS_PAGE_WHOISWATCHING);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // active layer whoIsWatching
  useEffect(() => {
    storeOneNavigation.setActiveLayer(LAYER_WHOISWATCHING);

    return () => {
      storeOneNavigation.setActiveLayer(LAYER_PAGE);
    };
  }, [storeOneNavigation]);

  // focus on first element of profiles list and display spinner client side if isProfileSelectorRendered is false
  useEffect(() => {
    if (isProfileSelectorRendered) {
      storeOneNavigation.focusDefault();
    } else {
      setSpinnerVisible(true);
    }
  }, [isProfileSelectorRendered, storeOneNavigation, setSpinnerVisible]);

  /**
   * Preload some chunks for CSR render mode
   * - LandingV5Container
   * - ContentRowStandard
   */
  usePreloadChunks([
    { type: 'landing', strateMode: 'standard' },
    { type: 'contentRow', strateMode: 'standard' },
  ]);

  /**
   * Manage Back action on layer LAYER_WHOISWATCHING (for TV):
   * - If focus is not on binder ProfileSelector, we force focus on ProfileSelector
   * - If focus is on ProfileSelector but not on first element, we force focus on first element
   */
  const handleBack = async () => {
    const firstElement = storeOneNavigation.currentBinder?.elements[0];

    if (
      storeOneNavigation.currentBinder?.id !== WIW_BINDER_ID ||
      storeOneNavigation.getActiveLayer().current !== firstElement
    ) {
      storeOneNavigation
        .getActiveLayer()
        .getBinderById(WIW_BINDER_ID)
        ?.focusByIndex(0);
    } else {
      try {
        await goBack();
      } catch (error) {
        console.error(error);
      }
    }
  };
  useKeyCatcher(KEY_BACK, handleBack, LAYER_WHOISWATCHING);

  const handleClickAvatar = () => {
    // set cookie session
    setCookieWhoIsWatchingSession();

    // Reset forceWhoIsWatching state to 'auto' to have the normal bahavior after a force display
    // It's important for hide the screen after select profile using inSession state
    dispatch(setForceWhoIsWatching('auto'));

    // update the store to indicate whoIsWatching has been displayed
    // this is the action hide WhoIsWatching screen and display app route (see in Application.tsx)
    dispatch(setWhoIsWatchingUserSession(true));
    dispatch(setWhoIsWatchingDisabled(isWhoIsWatchingDisabled));
  };

  const onClickToggleDisabled = () => {
    const newStateDisabled = !isWhoIsWatchingDisabled;

    // update component state with the new value
    setIsWhoIsWatchingDisabled(newStateDisabled);

    // update cookie
    setCookieWhoIsWatchingDisabled(newStateDisabled);
  };

  return (
    <div className={styles.whoIsWatching}>
      <div className={styles.whoIsWatching__logo}>
        {/* Wait hodor to get logos from init instead authenticate to display logo
          <HeaderLogoOneDiscoveryConnected />
        */}
      </div>

      <Binder
        layer={LAYER_WHOISWATCHING}
        binderId={WIW_BINDER_ID}
        enabled={$_BUILD_RENDERMODE_CSR}
        middleware={MIDDLEWARE_PROFILES_SELECTOR}
        className={cx({ 'whoIsWatching--hidden': !isProfileSelectorRendered })}
      >
        <h2
          className={cx('whoIsWatching__title', {
            'whoIsWatching__title--fadeIn': isProfileSelectorRendered,
          })}
        >
          {t('WhoIsWatching.title')}
        </h2>
        {isProfileSelectorRendered && (
          <ProfileSelector handleClickAvatar={handleClickAvatar} />
        )}
      </Binder>
      <Binder
        layer={LAYER_WHOISWATCHING}
        enabled={$_BUILD_RENDERMODE_CSR}
        className={cx('whoIsWatching__toggle', {
          'whoIsWatching__toggle--fadeIn': isProfileSelectorRendered,
          'whoIsWatching--hidden': !isProfileSelectorRendered,
        })}
        middleware={MIDDLEWARE_PROFILES_SELECTOR}
      >
        <List
          title={t('WhoIsWatching.toggleNotDisplay')}
          id="whoIsWatchingToggleLabelId"
          actionableElement={
            !$_BUILD_RENDERMODE_CSR ? (
              <ToggleSwitch
                aria-label={t('WhoIsWatching.toggleNotDisplay')}
                labelledById="whoIsWatchingToggleLabelId"
                isToggled={isWhoIsWatchingDisabled}
                onClick={onClickToggleDisabled}
              />
            ) : undefined
          }
          {...($_BUILD_RENDERMODE_CSR
            ? {
                onClick: onClickToggleDisabled,
                label: isWhoIsWatchingDisabled
                  ? t('Common.yes')
                  : t('Common.no'),
              }
            : {})}
        />
        <p
          className={cx('whoIsWatching__toggleSubtitle', {
            'whoIsWatching--hidden': !isProfileSelectorRendered,
          })}
        >
          {t('WhoIsWatching.toggleNotDisplayDesc')}
        </p>
      </Binder>

      {spinnerVisible && !isProfileSelectorRendered && <Spinner size={5} />}
    </div>
  );
}

export default WhoIsWatching;
