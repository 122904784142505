import { loginUser } from '@canalplus/ifc-onecore';
import { isClientSide } from '@canalplus/mycanal-commons';
import { getSigninUrl } from '@canalplus/sdk-pass';
import { usePassSdk } from '@canalplus/sdk-pass-react';

const loginUserHandler = () => {
  return loginUser();
};

/** Redirect to the authentication page */
export const useSigninRedirect = (): ((redirectUri?: string) => void) => {
  const signinUrl = usePassSdk(getSigninUrl);

  return $_BUILD_RENDERMODE_CSR
    ? loginUserHandler
    : (redirectUri = isClientSide() ? window.location.href : '') => {
        window.location.assign(signinUrl({ redirectUri }));
      };
};
