import type { DottedLanguageObjectStringPaths } from '@canalplus/mycanal-commons';
import type { I18nTranslationFunction } from '@canalplus/mycanal-i18n';
import { useTranslation as useTranslationLib } from '@canalplus/mycanal-i18n';
import type { OneDiscoveryTranslations } from './types';

export type OneDiscoveryTranslationsStringPaths =
  DottedLanguageObjectStringPaths<OneDiscoveryTranslations>;

export type I18nOneDiscoveryFunction =
  I18nTranslationFunction<OneDiscoveryTranslationsStringPaths>;

export const useTranslation =
  useTranslationLib<OneDiscoveryTranslationsStringPaths>;
