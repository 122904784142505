import { ProfileModal } from '@canalplus/mycanal-commons';
import type { ApiV2Profile } from '@dce-front/hodor-types/api/v2/me/profiles/definitions';

/**
 *
 * Get the prev `ProfileModal` view to determine if
 * we need to handle a back to the prev view
 * or  close the modal if we return undefined
 * We need to check if **profile** is defined to know
 * if we handle the ProfileEditionView (a **profile** is selected) or ProfileCreationView (new **profile**)
 */

export const getPrevProfilesView = (
  currentView: ProfileModal,
  profile?: ApiV2Profile,
): ProfileModal | undefined => {
  switch (currentView) {
    case ProfileModal.ProfileEditionView:
    case ProfileModal.ProfileCreationView:
      return ProfileModal.ProfileListView;
    case ProfileModal.AvatarSelectionView:
    case ProfileModal.DeleteConfirmationView:
      return profile
        ? ProfileModal.ProfileEditionView
        : ProfileModal.ProfileCreationView;
    case ProfileModal.ProfileCreationViewFromLanding:
    case ProfileModal.ProfileListView:
      return;
    default:
      return;
  }
};
