import { Avatar } from '@canalplus/dive';
import { DIMENSIONS } from '@canalplus/dive-utils';
import { ProfileModal } from '@canalplus/mycanal-commons';
import { Binder } from '@canalplus/one-navigation';
import type { ApiV2AvatarV1Content } from '@dce-front/hodor-types/api/v2/avatars/v1/definitions';
import type { ApiV2AvatarsV2Strate } from '@dce-front/hodor-types/api/v2/avatars/v2/definitions';
import type { ApiV2Profile } from '@dce-front/hodor-types/api/v2/me/profiles/definitions';
import classNames from 'classnames';
import type { JSX, SyntheticEvent } from 'react';
import { memo, useContext, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { TemplateTypes } from '../../../constants/templateTypes';
import { useAppDispatch } from '../../../helpers/hooks/useAppDispatch';
import {
  MIDDLEWARE_SETTINGS,
  MIDDLEWARE_VIRTUAL_KEYBOARD,
} from '../../../helpers/oneNavigation/middleware';
import type { AvatarTrackingData } from '../../../helpers/tracking/types';
import { useTranslation } from '../../../lang';
import {
  type ProfileToSend,
  deleteProfile,
  saveProfile,
  updateProfile,
} from '../../../services/profiles/ProfileService';
import { featAvatarV2Selector } from '../../../store/slices/application-selectors';
import {
  type ProfilesModal,
  setAvatar,
  setIsKidsProfileSettings,
  setProfileInput,
  setProfileView,
} from '../../../store/slices/profilesModal';
import { modalCurrentViewSelector } from '../../../store/slices/profilesModal-selectors';
import Button from '../../Button/Button';
import ChoiceButton from '../../ChoiceButton/ChoiceButton';
import Setting from '../../Setting/Setting';
import ThumborMediaImage from '../../ThumborMediaImage/ThumborMediaImage';
import {
  VirtualKeyboard,
  virtualKeyboardContext,
} from '../../VirtualKeyboard/VirtualKeyboard';
import { useUpdateListProfiles } from '../ProfileSelectorMyCanal/hooks/useUpdateListProfiles';
import { getFlattenedAvatarContents } from '../helpers/getFlattenedAvatarContents';
import { useDefaultAvatarURL } from '../hooks/useDefaultAvatarURL';
import { isAvatarV2Content } from '../types';
import styles from './ProfileForm.css';

export type ProfileFormProps = {
  allowSuppression?: boolean;
  avatarURL?: string;
  avatars: ApiV2AvatarV1Content[] | ApiV2AvatarsV2Strate[];
  featKidsProfiles: boolean;
  profile?: Partial<ApiV2Profile>;
  setOpenModal?: (isModalOpen: boolean) => void;
} & Pick<ProfilesModal, 'isKidsProfileSettings' | 'profileInput'>;

const PROFILE_MAX_INPUT_VALUE = 12;

function ProfileForm({
  allowSuppression,
  avatarURL,
  avatars,
  featKidsProfiles,
  isKidsProfileSettings,
  profile,
  profileInput,
  setOpenModal,
}: ProfileFormProps): JSX.Element {
  const DEFAULT_AVATAR_IDENTIFIER = 0;

  const dispatch = useAppDispatch();
  const isAvatarV2 = useSelector(featAvatarV2Selector);
  const currentView: ProfileModal = useSelector(modalCurrentViewSelector);
  const isFirstRender = useRef(true);

  const flattenedAvatarContents = getFlattenedAvatarContents({
    data: avatars,
    isAvatarV2,
  });
  const defaultAvatarURL = useDefaultAvatarURL(flattenedAvatarContents) || '';

  const { t } = useTranslation();

  const { updateListProfiles } = useUpdateListProfiles();

  const {
    state: { inputValue },
    setInputValue: setVirtualKeyBoardInputValue,
  } = useContext(virtualKeyboardContext);

  /**
   * Input value from **VirtualKeyboard** is a `string[]`\
   * We are transforming it into a simple string
   */
  const joinedVirtualKeyboardInputValue = inputValue.join('');

  useEffect(() => {
    if (avatarURL && !profile) {
      dispatch(setAvatar(avatarURL));
      dispatch(setProfileInput(profileInput || ''));
    } else if (profile) {
      dispatch(setAvatar(avatarURL || profile.URLImage || ''));
      dispatch(setProfileInput(profileInput || profile.displayName || ''));
      dispatch(setIsKidsProfileSettings(profile.isKidsProfile || false));

      /**
       * On TV device, if we have a profile (already existing),
       * we need to set the **profileInput || displayName** in the virtualKeyboardState **inputValue**
       * to have the possibility to add or suppress characters
       */
      if ($_BUILD_RENDERMODE_CSR) {
        setVirtualKeyBoardInputValue(profileInput || profile.displayName || '');
      }
    } else if (defaultAvatarURL) {
      dispatch(setAvatar(defaultAvatarURL));
      dispatch(setProfileInput(''));
    }
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [avatars, profile]);

  /**
   * Generate a random avatar (by default) if the setIsKidsProfileSettings action is triggered
   * when isAvatarV2 == true and currentView is different from ProfileEditionView
   * In this case, if we trigger setAvatar action when the component is mounted (first render)
   * the choice of the avatar by the user in the AvatarSelectionView
   * will be overwritten each time the user is redirected to the ProfileCreationView!
   */
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    if (isAvatarV2 && currentView !== ProfileModal.ProfileEditionView) {
      dispatch(setAvatar(defaultAvatarURL));
    }
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isKidsProfileSettings]);

  const displayNameFromInput = $_BUILD_RENDERMODE_CSR
    ? joinedVirtualKeyboardInputValue
    : profileInput;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if ($_BUILD_RENDERMODE_CSR) {
      setVirtualKeyBoardInputValue(event.target.value);
    }
    dispatch(setProfileInput(event.target.value));
  };

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();

    const selectedAvatar = flattenedAvatarContents?.find(
      ({ URLImage }) => URLImage === avatarURL,
    );
    const avatarIdentifier =
      selectedAvatar && isAvatarV2Content(selectedAvatar)
        ? selectedAvatar?.avatarID
        : selectedAvatar?.contentID;

    const selectedStrate =
      selectedAvatar && isAvatarV2Content(selectedAvatar)
        ? (avatars as ApiV2AvatarsV2Strate[]).find(({ contents }) =>
            contents?.includes(selectedAvatar),
          )?.title
        : '';

    const profileToSend: ProfileToSend = {
      displayName: displayNameFromInput,
      isKidsProfile: isKidsProfileSettings,
      avatar: {
        contentID: avatarIdentifier || DEFAULT_AVATAR_IDENTIFIER,
      },
      profileToken: profile?.profileToken,
      profileId: profile?.profileId,
    };

    const avatarTrackingData: AvatarTrackingData = {
      avatarId: avatarIdentifier,
      avatarName: selectedAvatar?.title,
      avatarListName: selectedStrate,
    };

    await dispatch(
      profile?.profileToken
        ? updateProfile(
            profileToSend,
            event,
            avatarTrackingData,
            updateListProfiles,
            setOpenModal,
          )
        : saveProfile(
            profileToSend,
            event,
            avatarTrackingData,
            updateListProfiles,
            setOpenModal,
          ),
    );
  };

  const handleDelete = async (event: SyntheticEvent) => {
    if ($_BUILD_RENDERMODE_CSR) {
      dispatch(setProfileView(ProfileModal.DeleteConfirmationView));
    } else if (profile) {
      await dispatch(deleteProfile(profile, updateListProfiles, event));
    }
  };

  const handleAvatarSelection = () => {
    if (avatars) {
      dispatch(setProfileView(ProfileModal.AvatarSelectionView));
    }
  };

  const isDisabled = $_BUILD_RENDERMODE_CSR
    ? !inputValue.length
    : !profileInput;

  const { defaultProfile, profileId } = profile || {};
  const showKidsSetting = featKidsProfiles && !defaultProfile && !profileId;

  return (
    <div className={classNames(styles.profileForm)}>
      <form onSubmit={handleSubmit}>
        <h2 className={styles.profileForm__title}>
          {profile ? t('ProfileManager.edit') : t('ProfileManager.addProfile')}
        </h2>

        <Binder middleware={MIDDLEWARE_SETTINGS}>
          <button
            type="button"
            aria-label={t('ProfileManager.editAvatarAriaLabel')}
            onClick={avatars ? () => handleAvatarSelection() : undefined}
            className={classNames(
              styles.profileForm__profileAvatarWrapper,
              'profileFormAvatar',
            )}
          >
            <Avatar
              aria-label={profile?.displayName ? profile.displayName : 'avatar'}
              image={
                <ThumborMediaImage
                  url={avatarURL}
                  urlMobile={avatarURL}
                  dimensions={DIMENSIONS.PROFILE_AVATAR.extraLarge}
                />
              }
              isKids={isKidsProfileSettings}
              className={styles.profileForm__profileAvatarEdit}
              isEdit
              isButton={false}
            />
          </button>
        </Binder>

        <input
          type="text"
          value={displayNameFromInput}
          onChange={handleChange}
          className={classNames(styles.profileForm__name)}
          placeholder={t('ProfileManager.inputPlaceholder')}
          maxLength={PROFILE_MAX_INPUT_VALUE}
          {...($_BUILD_RENDERMODE_CSR && { readOnly: true })}
        />
        {$_BUILD_RENDERMODE_CSR && (
          <Binder
            middleware={MIDDLEWARE_VIRTUAL_KEYBOARD}
            className={styles.profileForm__keyboard}
          >
            <VirtualKeyboard maxInputValue={PROFILE_MAX_INPUT_VALUE} />
          </Binder>
        )}
        {showKidsSetting &&
          ($_BUILD_RENDERMODE_CSR ? (
            <Binder middleware={MIDDLEWARE_SETTINGS}>
              <div className={styles.profileForm__kidsSettings}>
                <ChoiceButton
                  classname="profileFormKidsSetting"
                  handler={() =>
                    dispatch(setIsKidsProfileSettings(!isKidsProfileSettings))
                  }
                  label={t('ProfileManager.kidsProfileSettings')}
                  statusLabel={
                    isKidsProfileSettings ? t('Common.yes') : t('Common.no')
                  }
                />
                <p className={styles.profileForm__kidsSettings__description}>
                  {t('ProfileManager.kidsProfileSubText')}
                </p>
              </div>
            </Binder>
          ) : (
            <div className={styles.profileForm__kidsSettings}>
              <Setting
                isTypeFlipSwitch
                name="switchKids"
                id="switchKids"
                isChecked={isKidsProfileSettings}
                handleChange={() =>
                  dispatch(setIsKidsProfileSettings(!isKidsProfileSettings))
                }
              >
                <span className={styles.profileForm__kidsSettings__title}>
                  {t('ProfileManager.kidsProfileTitle')}
                </span>
                <p className={styles.profileForm__kidsSettings__description}>
                  {t('ProfileManager.kidsProfileSubText')}
                </p>
              </Setting>
            </div>
          ))}
        <Binder middleware={MIDDLEWARE_SETTINGS}>
          <div className={styles.profileForm__buttonWrapper}>
            <Button
              isSubmit
              className={classNames(
                styles.profileForm__button,
                'profileFormSubmitButtonFocus',
              )}
              color={
                isDisabled ? TemplateTypes.DISABLED : TemplateTypes.SECONDARY
              }
              text={
                profile
                  ? t('ProfileManager.submitEdition')
                  : t('ProfileManager.submitCreation')
              }
              isDisabled={isDisabled}
            />
            {allowSuppression && (
              <Button
                handler={handleDelete}
                className={classNames(
                  styles.profileForm__button,
                  'profileFormDeleteButtonFocus',
                )}
                color={
                  $_BUILD_RENDERMODE_CSR
                    ? TemplateTypes.TV
                    : TemplateTypes.PRIMARY
                }
                text={t('ProfileManager.deleteProfile')}
              />
            )}
          </div>
        </Binder>
      </form>
    </div>
  );
}

export default memo(ProfileForm);
